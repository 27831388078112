.container {
    min-width: 1000px;
    padding: 20px;
    position: relative;
    overflow: hidden;
}

.playerContainer {
    min-width: 1000px;
    padding: 20px;
    position: relative;
    height: calc(100vh - 110px);
    overflow-x: hidden;
    overflow-y: auto;
}

.highlight-color {
    color: #1cd156;
}

.mainContainer {
    font-size: 35px;
}

#githubContainer {
    position: fixed;
    width: 125px;
    height: 125px;
    transform: rotate(45deg);
    background: #1ed760;
    top: -75px;
    right: -75px;
    cursor: pointer;
    transition: background-color .2s, top .2s, right .2s, clip-path .2s;
    clip-path: polygon(50% 64%, 14% 100%, 86% 100%);
    z-index: 1;
}

#githubContainer:hover {
    background: #2ebd59;
    top: -65px;
    right: -65px;
    clip-path: polygon(50% 52%, 2% 100%, 98% 100%);
}

#githubContainer:hover #githubIcon {
    bottom: 10px;
}

#githubIcon {
    position: absolute;
    width: 30px;
    height: 30px;
    background: url("/assets/github.png");
    background-size: 30px 30px;
    transform: rotate(-45deg);
    bottom: 4px;
    right: 48px;
    transition: bottom .2s;
}

.mobileDialogTitle {
    font-size: 30pt;
    font-weight: 600;
    text-align: center;
}

.mobileDialogContent {
    font-size: 20pt;
    font-weight: 200;
    text-align: center;
    margin: 20px 0;
}

.mobileDialogButton {
    text-align: center;
    margin-bottom: 10px;
}


/* Common */
.no-display {
    display: none;
}

.transparent {
    opacity: 0;
}

.continueButtonContainer {
    text-align: center;
    margin-bottom: 20px;
}

.continueButton {
    display: inline-block;
    margin: 0 10px;
}

.errorMessage {
    max-width: 600px;
    text-align: center;
}

.errorMessage p {
    margin: 0;
}


/* Styles for: Home.jsx */
#welcomeMsg {
    display: block;
    position: relative;
    width: 100%;
    height: calc(100vh - 40px);
    line-height: calc(100vh - 40px);
    text-align: center;
    margin: 0;
    font-size: 70pt;
    animation: fadeOutTop 1s 1.5s forwards;
}

@keyframes fadeOutTop {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        transform: translateY(-200px);;
    }
}

#homeContainer {
    display: none;
    opacity: 0;
    animation: fadeIn 1s forwards;
    font-size: 14pt;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

#helloMsg {
    display: block;
    width: fit-content;
    margin: 0 auto;
    font-size: 70pt;
}

#clarification {
    font-family: 'Handlee', cursive;
    text-align: right;
    font-size: 15pt;
}

#me {
    display: block;
    width: 400px;
    height: 400px;
    object-fit: cover;
    margin: 30px auto;
}

#login {
    display: block;
    margin: 10px auto;
    width: fit-content;
}

#continueWithoutLogin {
    display: block;
    margin: 5px auto;
    width: fit-content;
    color: #bfbfbf;
    text-decoration: none;
    cursor: pointer;
    font-size: 14pt;
}

#continueWithoutLogin:hover {
    text-decoration: underline;
}

#premiumRequired {
    position: absolute;
    margin-top: -30px;
    left: calc(50% + 180px);
    width: 230px;
    font-size: 14pt;
}

#premiumRequired #arrow {
    width: 50px;
    transform: rotate(195deg) translateX(30px) translateY(-0px);
}

#tip {
    margin-top: 20px;
    text-align: center;
}

#goToResume {
    position: fixed;
    bottom: 10px;
    right: 10px;
    color: #ccc;
    text-decoration: none;
}

#goToResume:hover {
    color: white;
    text-decoration: underline;
}


/* Styles for: Welcome.jsx */
#welcomeInfoContainer {
    width: 1000px;
    margin: 0 auto;
}

#welcomeImg {
    display: inline-block;
    vertical-align: middle;
    width: 400px;
    height: 400px;
    object-fit: cover;
    margin-right: 32px;
    background-image: url("/images/img_2.png");
    background-size: 800px 400px;
    border: 1px solid white;
}

#welcomeImg:hover {
    background-position: -400px;
}

#welcomeImg figcaption {
    font-family: 'Handlee', cursive;
    font-size: 15pt;
    text-align: center;
    margin-top: 405px;
}

#welcomeInfoContainer #imgText {
    display: inline-block;
    vertical-align: middle;
    width: 500px;
}

#welcomeInfoContainer #imgText #p1 {
    margin: 0 0 70px;
}

#welcomeInfoContainer #imgText #p2 {
    margin: 70px 0 0;
}

#welcomeInfoContainer #motivation {
    text-align: center;
    color: #ccc;
    margin: 100px 0;
}

#adventure {
    font-size: 25px;
    text-align: center;
    margin-bottom: 20px;
}

#adventure #play {
    display: inline-block;
    width: 150px;
    height: 150px;
    border: 2px solid #1ed760;
    background-color: #1ed760;
    background-image: url("/assets/play.svg");
    background-repeat: no-repeat;
    background-position: 55px 25px;
    background-size: 50px 100px;
    border-radius: 75px;
    transition: transform .2s, background-color .2s;
    cursor: pointer;
}

#adventure #play:hover {
    transform: scale(1.02);
    background-color: rgba(30, 215, 96, 0.2);
}

#adventure #song {
    font-family: 'Handlee', cursive;
    text-align: right;
    position: absolute;
    margin: 20px 0 0 -25px;
    transform: rotate(-10deg);
}


/* Styles for: Hangman.jsx */
#hangmanImg {
    display: inline-block;
    vertical-align: middle;
    width: 250px;
    height: 250px;
    object-fit: cover;
    margin-right: 32px;
    background-image: url("/images/img_3.jpeg");
    background-size: cover;
    background-position: center;
    border: 1px solid white;
}

#hangmanImg figcaption {
    font-family: 'Handlee', cursive;
    font-size: 13pt;
    text-align: center;
    margin-top: 260px;
}

#hangmanInfoContainer {
    text-align: center;
}

#hangmanInfoContainer #imgText {
    text-align: left;
    font-size: 18pt;
    display: inline-block;
    vertical-align: middle;
    width: 400px;
}

#hangmanInfoContainer #imgText #p1 {
    margin: 0 0 70px;
}

#hangmanInfoContainer #imgText #p2 {
    margin: 70px 0 0;
}

#hangmanInfoContainer #hangmanFigure {
    display: inline-block;
    vertical-align: middle;
    margin-left: 100px;
}

.hangmanLetters {
    margin-top: 100px;
    text-align: center;
}

.hangmanLetter {
    width: 80px;
    height: 100px;
    background: transparent;
    outline: none;
    border: none;
    border-bottom: 5px solid white;
    margin: 0 10px;
    font-size: 80pt;
    text-align: center;
    color: white;
}

.hangmanLetter:focus {
    border-bottom: 5px solid #1ed760;
}

.hangmanLetterSpace {
    display: inline-block;
    width: 70px;
}

#hangmanLettersCaption {
    font-family: 'Handlee', cursive;
    text-align: center;
    font-size: 10pt;
    margin: 10px 0 50px 0;
}


/* Styles for: Languages.jsx */
#headerText {
    width: 850px;
    margin: 0 auto;
}


/* Styles for: Projects.jsx */
#projectsInitDialog {
    font-size: 16pt;
}

#projectsInitDialog #title {
    text-align: center;
    font-size: 20pt;
    margin-bottom: 20px;
}

#projectsInitDialog #findOut {
    text-align: center;
    margin-top: 20px;
}

#projects {
    text-align: center;
}

#projects .section {
    margin-bottom: 60px;
}

#projects .title {
    font-size: 60pt;
    color: #1ed760;
}

#projects .prjImageFrame {
    position: relative;
    display: inline-block;
    width: 200px;
    margin: 20px;
}

#projects .prjImage {
    width: 100%;
    border-radius: 5px;
}

#projects .prjTitle {
    position: absolute;
    font-size: 14pt;
    text-align: center;
    width: 100%;
    font-weight: 200;
}

#projects .prjOverlay {
    position: absolute;
    font-size: 14pt;
    text-align: center;
    width: calc(100% - 2px);
    height: calc(100% - 11px);
    border-radius: 5px;
    top: 0;
    opacity: 0;
    background: rgba(0, 0, 0, .5);
    transition: opacity .2s, box-shadow .2s ease-in, border .2s;
    cursor: pointer;
    border: 1px solid #000;
}

#projects .prjOverlay:hover {
    opacity: 1;
    box-shadow: 0 0 30px 2px rgba(0, 0, 0, .5);
    border: 1px solid #fff;
}

#projects .infoBtn {
    position: absolute;
    width: fit-content;
    margin: 0 auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-shadow: 0 0 10px black;
    font-weight: 200;
}

#projectDialog {
    min-width: 700px;
}

#projectDialog .projectDialogImage {
    display: inline-block;
    width: 300px;
    margin: 0 auto;
    border-radius: 5px;
}

#projectDialog .projectDialogContent {
    display: inline-block;
    width: calc(100% - 340px);
    margin: 0 20px;
    vertical-align: top;
}

#projectDialog .projectDialogTitle {
    font-size: 30pt;
}

#projectDialog .projectDialogDescription {
    font-size: 13pt;
    font-weight: 200;
}

#projectDialog #close {
    text-align: right;
    margin-top: 20px;
}

#whatIsNext {
    background: #1ed760;
    width: calc(100% + 40px);
    margin: 0 0 -20px -20px;
}

#whatIsNext .title {
    font-size: 60pt;
    color: black;
    text-align: center;
}

#whatIsNext .onlyYouDecide {
    text-shadow: none;
    font-family: 'Handlee', cursive;
    color: black;
    margin-top: -20px;
    text-align: center;
}

#whatIsNext .content {
    text-shadow: 0 0 1px rgba(0, 0, 0, .5);
    padding: 20px 0;
    width: fit-content;
    margin: 0 auto;
}

#whatIsNext a {
    color: white;
}

#whatIsNext .codeOfThis {
    position: absolute;
    font-family: 'Handlee', cursive;
    margin: 5px 0 0 10px;
    font-size: 15pt;
    transform: rotate(-5deg);
}

#whatIsNext .call {
    font-family: 'Handlee', cursive;
    color: black;
    text-align: center;
    font-weight: bold;
}

@media only screen and (max-width: 1040px) {
    .container {
        min-width: unset;
    }

    .playerContainer {
        min-width: unset;
        padding: 2vw;
        position: relative;
        height: unset;
        overflow-x: unset;
        overflow-y: unset;
        margin-bottom: 20vw;
    }


    /* Common */
    .continueButtonContainer {
        text-align: center;
        margin-bottom: unset;
    }

    .continueButton {
        margin: 1vw;
        display: block;
    }

    .errorMessage {
        font-size: 6vw;
    }

    .errorMessage p {
        margin: 6vw 0;
    }


    /* Styles for: Home.jsx */
    #welcomeMsg {
        font-size: 15vw;
    }

    #helloMsg {
        font-size: 13vw;
        text-align: center;
    }

    #clarification {
        text-align: center;
        font-size: 4vw;
    }

    #me {
        width: 80vw;
        height: 80vw;
        margin: 30px auto;
    }

    #login {
        margin: -6vw auto 10px auto;
    }

    #continueWithoutLogin {
        margin: 1vw auto;
        font-size: 4vw;
    }

    #premiumRequired {
        position: relative;
        margin: 0 auto;
        transform: translateX(-7vw);
        left: unset;
        width: 65vw;
        font-size: 5vw;
    }

    #premiumRequired #arrow {
        width: 10vw;
        height: 10vw;
        transform: rotate(90deg) translateX(-6vw) translateY(-62vw);
    }

    #tip {
        font-size: 4vw;
        margin-top: 15vw;
    }

    #goToResume {
        display: block;
        position: unset;
        text-align: center;
        width: calc(100% + 40px);
        margin: 10vh 0 -20px -20px;
        padding: 20px 0;
        border-top: 1px solid black;
        background: #222;
        bottom: 0;
        font-size: 4vw;
    }

    #goToResume:hover {
        text-decoration: none;
        background: #333;
    }


    /* Styles for: Welcome.jsx */
    #welcomeInfoContainer {
        width: 100%;
    }

    #welcomeImg {
        display: block;
        width: 80vw;
        height: 80vw;
        margin: 30px auto;
        background-image: url("/images/img_2.png");
        background-size: 160vw 80vw;
        background-position: 80vw;
    }

    #welcomeImg:hover {
        background-position: 80vw;
    }

    #welcomeImg figcaption {
        display: none;
    }

    #welcomeInfoContainer #imgText {
        display: block;
        font-size: 5vw;
        width: unset;
        text-align: center;
    }

    #welcomeInfoContainer #imgText #p1 {
        margin: 10vw 0 0 0;
    }

    #welcomeInfoContainer #imgText #p2 {
        margin: 10vw 0 0 0;
        padding: 0 1.5vw;
    }

    #welcomeInfoContainer #motivation {
        text-align: center;
        color: #ccc;
        margin: 30vw 0;
        font-size: 5vw;
    }

    #adventure #play {
        display: block;
        margin: 0 auto;
        width: 40vw;
        height: 40vw;
        background-position: 14vw 5vw;
        background-size: 15vw 30vw;
        border-radius: 20vw;
    }

    #adventure {
        font-size: 5vw;
        text-align: center;
        margin-bottom: 4vw;
    }

    #adventure p {
        margin-top: 4vw !important;
    }

    #adventure #song {
        font-size: 5vw;
        margin: 4vw 0 0 -5vw;
    }


    /* Styles for: Hangman.jsx */
    #hangmanImg {
        display: block;
        width: 80vw;
        height: 80vw;
        margin: 30px auto;
        background-image: url("/images/img_3.jpeg");
        background-size: 80vw 80vw;
        background-position: 0;
        vertical-align: unset;
        object-fit: unset;
    }

    #hangmanImg figcaption {
        font-family: 'Handlee', cursive;
        font-size: 4vw;
        margin-top: 81vw;
    }

    #hangmanInfoContainer #imgText {
        display: block;
        font-size: 5vw;
        width: unset;
        text-align: center;
        vertical-align: unset;
    }

    #hangmanInfoContainer #imgText #p1 {
        margin: 15vw 0 0 0;
    }

    #hangmanInfoContainer #imgText #p2 {
        margin: 10vw 0 0 0;
    }

    #hangmanInfoContainer #hangmanFigure {
        display: block;
        vertical-align: unset;
        margin: 5vw auto;
    }

    .hangmanLetters {
        margin-top: 2vw;
        text-align: center;
    }

    .hangmanLetter {
        width: 6vw;
        height: 7vw;
        border-bottom: .5vw solid white;
        margin: 0 1.5vw;
        font-size: 8vw;
    }

    .hangmanLetter:focus {
        border-bottom: .5vw solid #1ed760;
    }

    .hangmanLetterSpace {
        display: inline-block;
        width: 6vw;
    }

    #hangmanLettersCaption {
        font-size: 4vw;
        margin: 1vw 0;
    }


    /* Styles for: Languages.jsx */
    #headerText {
        width: calc(100% - 4vw);
        margin: 5vw auto 7vw auto;
        text-align: center;
        font-size: 5vw;
    }


    /* Styles for: Projects.jsx */
    #projectsInitDialog {
        font-size: 4vw;
    }

    #projectsInitDialog #title {
        font-size: 6vw;
        margin-bottom: 4vw;
    }

    #projectsInitDialog #findOut {
        margin-top: 4vw;
    }

    #projects .section {
        margin-bottom: 10vw;
    }

    #projects .title {
        font-size: 15vw;
    }

    #projects .prjImageFrame {
        width: 30vw;
        margin: 3vw;
    }

    #projects .prjTitle {
        position: absolute;
        font-size: 3vw;
    }

    #projects .prjOverlay {
        font-size: 3vw;
    }

    #projects .infoBtn {
        text-shadow: 0 0 2vw black;
    }

    #projectDialog {
        min-width: unset;
    }

    #projectDialog .projectDialogImage {
        display: block;
        width: 40vw;
        margin: 0 auto;
        border-radius: 5px;
    }

    #projectDialog .projectDialogContent {
        display: block;
        width: unset;
        margin: 0 2vw;
    }

    #projectDialog .projectDialogTitle {
        text-align: center;
        font-size: 7vw;
    }

    #projectDialog .projectDialogDescription {
        font-size: 4vw;
    }

    #projectDialog #close {
        text-align: center;
        margin-top: 4vw;
    }

    #whatIsNext {
        background: #1ed760;
        width: calc(100% + 4vw);
        margin: 0 0 -2vw -2vw;
    }

    #whatIsNext .title {
        font-size: 15vw;
    }

    #whatIsNext .onlyYouDecide {
        font-size: 7vw;
        margin-top: -2vw;
    }

    #whatIsNext .content {
        padding: 2vw 0;
        width: fit-content;
        margin: 0 auto;
        font-size: 7vw;
    }

    #whatIsNext .codeOfThis {
        display: none;
    }

    #whatIsNext .call {
        padding-top: 2vw;
        font-size: 7vw;
    }
}