@font-face {
    font-family: spotify-circular;
    src: url(../../static/media/CircularSpUIv3T-Light.fa847326.woff2) format("woff2");
    font-weight: 200;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: spotify-circular;
    src: url(../../static/media/CircularSpUIv3T-Book.6ff898ba.woff2) format("woff2");
    font-weight: 400;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: spotify-circular;
    src: url(../../static/media/CircularSpUIv3T-Bold.c147cc23.woff2) format("woff2");
    font-weight: 600;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: 'Handlee';
    src: url(../../static/media/Handlee.9c798c3c.woff2) format('woff2');
    font-weight: 400;
    font-style: normal;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

body {
    margin: 0;
    padding: 0;
    font-family: spotify-circular, Helvetica Neue, Helvetica, Arial, sans-serif;
    background: rgba(65, 45, 78, 1);
    background: -webkit-gradient(linear, left top, right bottom, color-stop(0%, rgba(65, 45, 78, 1)), color-stop(100%, rgba(0, 0, 0, 1)));
    background: -webkit-linear-gradient(-45deg, rgba(65, 45, 78, 1) 0%, rgba(0, 0, 0, 1) 100%);
    background: -webkit-linear-gradient(315deg, rgba(65, 45, 78, 1) 0%, rgba(0, 0, 0, 1) 100%);
    background: linear-gradient(135deg, rgba(65, 45, 78, 1) 0%, rgba(0, 0, 0, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#412d4e', endColorstr='#000000', GradientType=1);
    color: white;
    background-attachment: fixed;
    -webkit-tap-highlight-color: transparent;
}

@media only screen and (max-width: 1040px) {
    body {
        background: #30213a;
    }
}
.container {
    min-width: 1000px;
    padding: 20px;
    position: relative;
    overflow: hidden;
}

.playerContainer {
    min-width: 1000px;
    padding: 20px;
    position: relative;
    height: calc(100vh - 110px);
    overflow-x: hidden;
    overflow-y: auto;
}

.highlight-color {
    color: #1cd156;
}

.mainContainer {
    font-size: 35px;
}

#githubContainer {
    position: fixed;
    width: 125px;
    height: 125px;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    background: #1ed760;
    top: -75px;
    right: -75px;
    cursor: pointer;
    -webkit-transition: background-color .2s, top .2s, right .2s, -webkit-clip-path .2s;
    transition: background-color .2s, top .2s, right .2s, -webkit-clip-path .2s;
    transition: background-color .2s, top .2s, right .2s, clip-path .2s;
    transition: background-color .2s, top .2s, right .2s, clip-path .2s, -webkit-clip-path .2s;
    -webkit-clip-path: polygon(50% 64%, 14% 100%, 86% 100%);
            clip-path: polygon(50% 64%, 14% 100%, 86% 100%);
    z-index: 1;
}

#githubContainer:hover {
    background: #2ebd59;
    top: -65px;
    right: -65px;
    -webkit-clip-path: polygon(50% 52%, 2% 100%, 98% 100%);
            clip-path: polygon(50% 52%, 2% 100%, 98% 100%);
}

#githubContainer:hover #githubIcon {
    bottom: 10px;
}

#githubIcon {
    position: absolute;
    width: 30px;
    height: 30px;
    background: url("/assets/github.png");
    background-size: 30px 30px;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    bottom: 4px;
    right: 48px;
    -webkit-transition: bottom .2s;
    transition: bottom .2s;
}

.mobileDialogTitle {
    font-size: 30pt;
    font-weight: 600;
    text-align: center;
}

.mobileDialogContent {
    font-size: 20pt;
    font-weight: 200;
    text-align: center;
    margin: 20px 0;
}

.mobileDialogButton {
    text-align: center;
    margin-bottom: 10px;
}


/* Common */
.no-display {
    display: none;
}

.transparent {
    opacity: 0;
}

.continueButtonContainer {
    text-align: center;
    margin-bottom: 20px;
}

.continueButton {
    display: inline-block;
    margin: 0 10px;
}

.errorMessage {
    max-width: 600px;
    text-align: center;
}

.errorMessage p {
    margin: 0;
}


/* Styles for: Home.jsx */
#welcomeMsg {
    display: block;
    position: relative;
    width: 100%;
    height: calc(100vh - 40px);
    line-height: calc(100vh - 40px);
    text-align: center;
    margin: 0;
    font-size: 70pt;
    -webkit-animation: fadeOutTop 1s 1.5s forwards;
            animation: fadeOutTop 1s 1.5s forwards;
}

@-webkit-keyframes fadeOutTop {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        -webkit-transform: translateY(-200px);
                transform: translateY(-200px);;
    }
}

@keyframes fadeOutTop {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        -webkit-transform: translateY(-200px);
                transform: translateY(-200px);;
    }
}

#homeContainer {
    display: none;
    opacity: 0;
    -webkit-animation: fadeIn 1s forwards;
            animation: fadeIn 1s forwards;
    font-size: 14pt;
}

@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

#helloMsg {
    display: block;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
    font-size: 70pt;
}

#clarification {
    font-family: 'Handlee', cursive;
    text-align: right;
    font-size: 15pt;
}

#me {
    display: block;
    width: 400px;
    height: 400px;
    object-fit: cover;
    margin: 30px auto;
}

#login {
    display: block;
    margin: 10px auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

#continueWithoutLogin {
    display: block;
    margin: 5px auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    color: #bfbfbf;
    text-decoration: none;
    cursor: pointer;
    font-size: 14pt;
}

#continueWithoutLogin:hover {
    text-decoration: underline;
}

#premiumRequired {
    position: absolute;
    margin-top: -30px;
    left: calc(50% + 180px);
    width: 230px;
    font-size: 14pt;
}

#premiumRequired #arrow {
    width: 50px;
    -webkit-transform: rotate(195deg) translateX(30px) translateY(-0px);
            transform: rotate(195deg) translateX(30px) translateY(-0px);
}

#tip {
    margin-top: 20px;
    text-align: center;
}

#goToResume {
    position: fixed;
    bottom: 10px;
    right: 10px;
    color: #ccc;
    text-decoration: none;
}

#goToResume:hover {
    color: white;
    text-decoration: underline;
}


/* Styles for: Welcome.jsx */
#welcomeInfoContainer {
    width: 1000px;
    margin: 0 auto;
}

#welcomeImg {
    display: inline-block;
    vertical-align: middle;
    width: 400px;
    height: 400px;
    object-fit: cover;
    margin-right: 32px;
    background-image: url("/images/img_2.png");
    background-size: 800px 400px;
    border: 1px solid white;
}

#welcomeImg:hover {
    background-position: -400px;
}

#welcomeImg figcaption {
    font-family: 'Handlee', cursive;
    font-size: 15pt;
    text-align: center;
    margin-top: 405px;
}

#welcomeInfoContainer #imgText {
    display: inline-block;
    vertical-align: middle;
    width: 500px;
}

#welcomeInfoContainer #imgText #p1 {
    margin: 0 0 70px;
}

#welcomeInfoContainer #imgText #p2 {
    margin: 70px 0 0;
}

#welcomeInfoContainer #motivation {
    text-align: center;
    color: #ccc;
    margin: 100px 0;
}

#adventure {
    font-size: 25px;
    text-align: center;
    margin-bottom: 20px;
}

#adventure #play {
    display: inline-block;
    width: 150px;
    height: 150px;
    border: 2px solid #1ed760;
    background-color: #1ed760;
    background-image: url("/assets/play.svg");
    background-repeat: no-repeat;
    background-position: 55px 25px;
    background-size: 50px 100px;
    border-radius: 75px;
    -webkit-transition: background-color .2s, -webkit-transform .2s;
    transition: background-color .2s, -webkit-transform .2s;
    transition: transform .2s, background-color .2s;
    transition: transform .2s, background-color .2s, -webkit-transform .2s;
    cursor: pointer;
}

#adventure #play:hover {
    -webkit-transform: scale(1.02);
            transform: scale(1.02);
    background-color: rgba(30, 215, 96, 0.2);
}

#adventure #song {
    font-family: 'Handlee', cursive;
    text-align: right;
    position: absolute;
    margin: 20px 0 0 -25px;
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
}


/* Styles for: Hangman.jsx */
#hangmanImg {
    display: inline-block;
    vertical-align: middle;
    width: 250px;
    height: 250px;
    object-fit: cover;
    margin-right: 32px;
    background-image: url("/images/img_3.jpeg");
    background-size: cover;
    background-position: center;
    border: 1px solid white;
}

#hangmanImg figcaption {
    font-family: 'Handlee', cursive;
    font-size: 13pt;
    text-align: center;
    margin-top: 260px;
}

#hangmanInfoContainer {
    text-align: center;
}

#hangmanInfoContainer #imgText {
    text-align: left;
    font-size: 18pt;
    display: inline-block;
    vertical-align: middle;
    width: 400px;
}

#hangmanInfoContainer #imgText #p1 {
    margin: 0 0 70px;
}

#hangmanInfoContainer #imgText #p2 {
    margin: 70px 0 0;
}

#hangmanInfoContainer #hangmanFigure {
    display: inline-block;
    vertical-align: middle;
    margin-left: 100px;
}

.hangmanLetters {
    margin-top: 100px;
    text-align: center;
}

.hangmanLetter {
    width: 80px;
    height: 100px;
    background: transparent;
    outline: none;
    border: none;
    border-bottom: 5px solid white;
    margin: 0 10px;
    font-size: 80pt;
    text-align: center;
    color: white;
}

.hangmanLetter:focus {
    border-bottom: 5px solid #1ed760;
}

.hangmanLetterSpace {
    display: inline-block;
    width: 70px;
}

#hangmanLettersCaption {
    font-family: 'Handlee', cursive;
    text-align: center;
    font-size: 10pt;
    margin: 10px 0 50px 0;
}


/* Styles for: Languages.jsx */
#headerText {
    width: 850px;
    margin: 0 auto;
}


/* Styles for: Projects.jsx */
#projectsInitDialog {
    font-size: 16pt;
}

#projectsInitDialog #title {
    text-align: center;
    font-size: 20pt;
    margin-bottom: 20px;
}

#projectsInitDialog #findOut {
    text-align: center;
    margin-top: 20px;
}

#projects {
    text-align: center;
}

#projects .section {
    margin-bottom: 60px;
}

#projects .title {
    font-size: 60pt;
    color: #1ed760;
}

#projects .prjImageFrame {
    position: relative;
    display: inline-block;
    width: 200px;
    margin: 20px;
}

#projects .prjImage {
    width: 100%;
    border-radius: 5px;
}

#projects .prjTitle {
    position: absolute;
    font-size: 14pt;
    text-align: center;
    width: 100%;
    font-weight: 200;
}

#projects .prjOverlay {
    position: absolute;
    font-size: 14pt;
    text-align: center;
    width: calc(100% - 2px);
    height: calc(100% - 11px);
    border-radius: 5px;
    top: 0;
    opacity: 0;
    background: rgba(0, 0, 0, .5);
    -webkit-transition: opacity .2s, box-shadow .2s ease-in, border .2s;
    transition: opacity .2s, box-shadow .2s ease-in, border .2s;
    cursor: pointer;
    border: 1px solid #000;
}

#projects .prjOverlay:hover {
    opacity: 1;
    box-shadow: 0 0 30px 2px rgba(0, 0, 0, .5);
    border: 1px solid #fff;
}

#projects .infoBtn {
    position: absolute;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    text-shadow: 0 0 10px black;
    font-weight: 200;
}

#projectDialog {
    min-width: 700px;
}

#projectDialog .projectDialogImage {
    display: inline-block;
    width: 300px;
    margin: 0 auto;
    border-radius: 5px;
}

#projectDialog .projectDialogContent {
    display: inline-block;
    width: calc(100% - 340px);
    margin: 0 20px;
    vertical-align: top;
}

#projectDialog .projectDialogTitle {
    font-size: 30pt;
}

#projectDialog .projectDialogDescription {
    font-size: 13pt;
    font-weight: 200;
}

#projectDialog #close {
    text-align: right;
    margin-top: 20px;
}

#whatIsNext {
    background: #1ed760;
    width: calc(100% + 40px);
    margin: 0 0 -20px -20px;
}

#whatIsNext .title {
    font-size: 60pt;
    color: black;
    text-align: center;
}

#whatIsNext .onlyYouDecide {
    text-shadow: none;
    font-family: 'Handlee', cursive;
    color: black;
    margin-top: -20px;
    text-align: center;
}

#whatIsNext .content {
    text-shadow: 0 0 1px rgba(0, 0, 0, .5);
    padding: 20px 0;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
}

#whatIsNext a {
    color: white;
}

#whatIsNext .codeOfThis {
    position: absolute;
    font-family: 'Handlee', cursive;
    margin: 5px 0 0 10px;
    font-size: 15pt;
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
}

#whatIsNext .call {
    font-family: 'Handlee', cursive;
    color: black;
    text-align: center;
    font-weight: bold;
}

@media only screen and (max-width: 1040px) {
    .container {
        min-width: unset;
    }

    .playerContainer {
        min-width: unset;
        padding: 2vw;
        position: relative;
        height: unset;
        overflow-x: unset;
        overflow-y: unset;
        margin-bottom: 20vw;
    }


    /* Common */
    .continueButtonContainer {
        text-align: center;
        margin-bottom: unset;
    }

    .continueButton {
        margin: 1vw;
        display: block;
    }

    .errorMessage {
        font-size: 6vw;
    }

    .errorMessage p {
        margin: 6vw 0;
    }


    /* Styles for: Home.jsx */
    #welcomeMsg {
        font-size: 15vw;
    }

    #helloMsg {
        font-size: 13vw;
        text-align: center;
    }

    #clarification {
        text-align: center;
        font-size: 4vw;
    }

    #me {
        width: 80vw;
        height: 80vw;
        margin: 30px auto;
    }

    #login {
        margin: -6vw auto 10px auto;
    }

    #continueWithoutLogin {
        margin: 1vw auto;
        font-size: 4vw;
    }

    #premiumRequired {
        position: relative;
        margin: 0 auto;
        -webkit-transform: translateX(-7vw);
                transform: translateX(-7vw);
        left: unset;
        width: 65vw;
        font-size: 5vw;
    }

    #premiumRequired #arrow {
        width: 10vw;
        height: 10vw;
        -webkit-transform: rotate(90deg) translateX(-6vw) translateY(-62vw);
                transform: rotate(90deg) translateX(-6vw) translateY(-62vw);
    }

    #tip {
        font-size: 4vw;
        margin-top: 15vw;
    }

    #goToResume {
        display: block;
        position: unset;
        text-align: center;
        width: calc(100% + 40px);
        margin: 10vh 0 -20px -20px;
        padding: 20px 0;
        border-top: 1px solid black;
        background: #222;
        bottom: 0;
        font-size: 4vw;
    }

    #goToResume:hover {
        text-decoration: none;
        background: #333;
    }


    /* Styles for: Welcome.jsx */
    #welcomeInfoContainer {
        width: 100%;
    }

    #welcomeImg {
        display: block;
        width: 80vw;
        height: 80vw;
        margin: 30px auto;
        background-image: url("/images/img_2.png");
        background-size: 160vw 80vw;
        background-position: 80vw;
    }

    #welcomeImg:hover {
        background-position: 80vw;
    }

    #welcomeImg figcaption {
        display: none;
    }

    #welcomeInfoContainer #imgText {
        display: block;
        font-size: 5vw;
        width: unset;
        text-align: center;
    }

    #welcomeInfoContainer #imgText #p1 {
        margin: 10vw 0 0 0;
    }

    #welcomeInfoContainer #imgText #p2 {
        margin: 10vw 0 0 0;
        padding: 0 1.5vw;
    }

    #welcomeInfoContainer #motivation {
        text-align: center;
        color: #ccc;
        margin: 30vw 0;
        font-size: 5vw;
    }

    #adventure #play {
        display: block;
        margin: 0 auto;
        width: 40vw;
        height: 40vw;
        background-position: 14vw 5vw;
        background-size: 15vw 30vw;
        border-radius: 20vw;
    }

    #adventure {
        font-size: 5vw;
        text-align: center;
        margin-bottom: 4vw;
    }

    #adventure p {
        margin-top: 4vw !important;
    }

    #adventure #song {
        font-size: 5vw;
        margin: 4vw 0 0 -5vw;
    }


    /* Styles for: Hangman.jsx */
    #hangmanImg {
        display: block;
        width: 80vw;
        height: 80vw;
        margin: 30px auto;
        background-image: url("/images/img_3.jpeg");
        background-size: 80vw 80vw;
        background-position: 0;
        vertical-align: unset;
        object-fit: unset;
    }

    #hangmanImg figcaption {
        font-family: 'Handlee', cursive;
        font-size: 4vw;
        margin-top: 81vw;
    }

    #hangmanInfoContainer #imgText {
        display: block;
        font-size: 5vw;
        width: unset;
        text-align: center;
        vertical-align: unset;
    }

    #hangmanInfoContainer #imgText #p1 {
        margin: 15vw 0 0 0;
    }

    #hangmanInfoContainer #imgText #p2 {
        margin: 10vw 0 0 0;
    }

    #hangmanInfoContainer #hangmanFigure {
        display: block;
        vertical-align: unset;
        margin: 5vw auto;
    }

    .hangmanLetters {
        margin-top: 2vw;
        text-align: center;
    }

    .hangmanLetter {
        width: 6vw;
        height: 7vw;
        border-bottom: .5vw solid white;
        margin: 0 1.5vw;
        font-size: 8vw;
    }

    .hangmanLetter:focus {
        border-bottom: .5vw solid #1ed760;
    }

    .hangmanLetterSpace {
        display: inline-block;
        width: 6vw;
    }

    #hangmanLettersCaption {
        font-size: 4vw;
        margin: 1vw 0;
    }


    /* Styles for: Languages.jsx */
    #headerText {
        width: calc(100% - 4vw);
        margin: 5vw auto 7vw auto;
        text-align: center;
        font-size: 5vw;
    }


    /* Styles for: Projects.jsx */
    #projectsInitDialog {
        font-size: 4vw;
    }

    #projectsInitDialog #title {
        font-size: 6vw;
        margin-bottom: 4vw;
    }

    #projectsInitDialog #findOut {
        margin-top: 4vw;
    }

    #projects .section {
        margin-bottom: 10vw;
    }

    #projects .title {
        font-size: 15vw;
    }

    #projects .prjImageFrame {
        width: 30vw;
        margin: 3vw;
    }

    #projects .prjTitle {
        position: absolute;
        font-size: 3vw;
    }

    #projects .prjOverlay {
        font-size: 3vw;
    }

    #projects .infoBtn {
        text-shadow: 0 0 2vw black;
    }

    #projectDialog {
        min-width: unset;
    }

    #projectDialog .projectDialogImage {
        display: block;
        width: 40vw;
        margin: 0 auto;
        border-radius: 5px;
    }

    #projectDialog .projectDialogContent {
        display: block;
        width: unset;
        margin: 0 2vw;
    }

    #projectDialog .projectDialogTitle {
        text-align: center;
        font-size: 7vw;
    }

    #projectDialog .projectDialogDescription {
        font-size: 4vw;
    }

    #projectDialog #close {
        text-align: center;
        margin-top: 4vw;
    }

    #whatIsNext {
        background: #1ed760;
        width: calc(100% + 4vw);
        margin: 0 0 -2vw -2vw;
    }

    #whatIsNext .title {
        font-size: 15vw;
    }

    #whatIsNext .onlyYouDecide {
        font-size: 7vw;
        margin-top: -2vw;
    }

    #whatIsNext .content {
        padding: 2vw 0;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        margin: 0 auto;
        font-size: 7vw;
    }

    #whatIsNext .codeOfThis {
        display: none;
    }

    #whatIsNext .call {
        padding-top: 2vw;
        font-size: 7vw;
    }
}
.style_player__1hVNQ {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 70px;
    background: #282828;
    border-top: 1px solid black;
    color: white;
}

.style_text__pZwK0 {
    width: 100%;
    text-align: center;
    margin: 10px;
    font-size: 20pt;
}

.style_progress__16AbE {
    display: block;
    margin: 0 auto;
    width: calc(100% - 40px) !important;
}

.style_progressSymbol__1vW3z {
    display: none !important;
}

.style_mute__1uxG1 {
    position: absolute;
    top: 10px;
    left: 20px;
    fill: white;
    -webkit-filter: invert(.8);
            filter: invert(.8);
    cursor: pointer;
    -webkit-transition: -webkit-filter .2s, -webkit-transform .2s;
    transition: -webkit-filter .2s, -webkit-transform .2s;
    transition: filter .2s, transform .2s;
    transition: filter .2s, transform .2s, -webkit-filter .2s, -webkit-transform .2s;
}

.style_mute__1uxG1:hover {
    -webkit-filter: invert(1);
            filter: invert(1);
}

.style_mute__1uxG1 img {
    width: 35px;
    height: 35px;
}

.style_highlightMute__2aF-q {
    -webkit-filter: invert(1) !important;
            filter: invert(1) !important;
    -webkit-animation: style_shake__1ohYU .2s infinite;
            animation: style_shake__1ohYU .2s infinite;
}

@-webkit-keyframes style_shake__1ohYU {
    0% {
        -webkit-transform: translateX(-5px);
                transform: translateX(-5px);
    }
    50% {
        -webkit-transform: translateX(5px);
                transform: translateX(5px);
    }
    100% {
        -webkit-transform: translateX(-5px);
                transform: translateX(-5px);
    }
}

@keyframes style_shake__1ohYU {
    0% {
        -webkit-transform: translateX(-5px);
                transform: translateX(-5px);
    }
    50% {
        -webkit-transform: translateX(5px);
                transform: translateX(5px);
    }
    100% {
        -webkit-transform: translateX(-5px);
                transform: translateX(-5px);
    }
}


@media only screen and (max-width: 1040px) {
    .style_player__1hVNQ {
        height: 20vw;
    }

    .style_text__pZwK0 {
        position: absolute;
        margin: 0;
        font-size: 5vw;
        left: 20.5vw;
        text-align: left;
        width: 77vw;
        display: table;
        height: 100%;
    }

    .style_text__pZwK0 span {
        display: table-cell;
        vertical-align: middle;
    }

    .style_player__1hVNQ svg {
        position: absolute;
        display: block;
        width: unset;
        top: 2vw;
        left: 2vw;
    }

    .style_mute__1uxG1 {
        position: absolute;
        top: 5vw;
        left: 5vw;
        z-index: 1;
    }

    .style_mute__1uxG1 img {
        width: 10vw;
        height: 10vw;
    }
}
.button_button__2ixMZ {
    display: inline-block;
    padding: 5px 25px 5px;
    background-color: #1ed760;
    border-radius: 100px;
    font-size: 16px;
    line-height: 1.5;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: .0625rem;
    text-decoration: none;
    font-weight: 600;
    cursor: pointer;
    vertical-align: middle;
    -webkit-transition: background-color .2s, color .2s;
    transition: background-color .2s, color .2s;
}

.button_button__2ixMZ:hover {
    background-color: #2ebd59;
}

.button_button__2ixMZ.button_small__2AXLB {
    padding: 3px 15px 3px;
    font-size: 10px;
}

.button_button__2ixMZ.button_medium__2mdJR {
    padding: 5px 25px 5px;
    font-size: 16px;
}

.button_button__2ixMZ.button_large__2JdH5 {
    padding: 11px 35px 9px;
    font-size: 18px;
}

.button_button__2ixMZ.button_disabled__OePrh {
    background-color: #bbb;
    cursor: default;
}

@media only screen and (max-width: 1040px) {
    .button_button__2ixMZ.button_small__2AXLB {
        padding: 1vw 5vw;
        font-size: 3vw;
    }

    .button_button__2ixMZ.button_medium__2mdJR {
        padding: 1.5vw 7vw;
        font-size: 4vw;
    }

    .button_button__2ixMZ.button_large__2JdH5 {
        padding: 2vw 8vw;
        font-size: 5vw;
    }
}
.style_figure__3MiwW {
    position: relative;
    width: 207px;
    height: 310px;
}

.style_poleBase__LB7lw {
    position: absolute;
    background: white;
    width: 100px;
    height: 10px;
    top: 300px;
    left: 107px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.style_pole__1L6gD {
    position: absolute;
    background: white;
    width: 10px;
    height: 300px;
    top: 0;
    left: 152px;
}

.style_poleTop__3dys9 {
    position: absolute;
    background: white;
    width: 150px;
    height: 10px;
    top: 0;
    left: 12px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.style_figure__3MiwW {
    -webkit-transition: opacity .1s;
    transition: opacity .1s;
}

.style_string__3pmbf {
    position: absolute;
    background: white;
    width: 2px;
    height: 50px;
    top: 0;
    left: 37px;
}

.style_head__1SmMh {
    position: absolute;
    border: 5px solid white;
    width: 40px;
    height: 40px;
    top: 50px;
    left: 12px;
    border-radius: 25px;
}

.style_body__3sful {
    position: absolute;
    background: white;
    width: 5px;
    height: 80px;
    top: 100px;
    left: 35px;
}

.style_leftArm__2hquB {
    position: absolute;
    background: white;
    width: 5px;
    height: 50px;
    top: 100px;
    left: 17px;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}

.style_rightArm__3il4c {
    position: absolute;
    background: white;
    width: 5px;
    height: 50px;
    top: 100px;
    left: 53px;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}

.style_leftLeg__-vHLa {
    position: absolute;
    background: white;
    width: 5px;
    height: 80px;
    top: 175px;
    left: 25px;
    -webkit-transform: rotate(15deg);
            transform: rotate(15deg);
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}

.style_rightLeg__3WQ2U {
    position: absolute;
    background: white;
    width: 5px;
    height: 80px;
    top: 175px;
    left: 45px;
    -webkit-transform: rotate(-15deg);
            transform: rotate(-15deg);
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}
.style_frame__1F6J0 {
    width: 1000px;
    height: 600px;
    margin: 20px auto;
    border: 1px solid #999;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
}

.style_langList__1FCbL {
    display: inline-block;
    width: 150px;
    height: 580px;
    text-align: left;
    padding: 10px;
    background: rgba(255, 255, 255, 0.1);
    border-right: 1px solid #999;
    overflow: hidden;
}

.style_langArena__3mTHk {
    position: relative;
    display: inline-block;
    width: 829px;
    height: 600px;
    overflow: hidden;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    text-align: center;
}

.style_langElement__1F0bU {
    font-size: 15pt;
    padding: 2px;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.style_langArena__3mTHk .style_langElement__1F0bU {
    position: absolute;
    width: 200px;
    height: 116px;
    line-height: 116px;
    -webkit-animation: style_dancing__3DzW9 1.3s infinite;
            animation: style_dancing__3DzW9 1.3s infinite;
    cursor: pointer;
    text-align: center;
}

@-webkit-keyframes style_dancing__3DzW9 {
    0% {
        -webkit-transform: rotate(5deg) translate(-5px, 0);
                transform: rotate(5deg) translate(-5px, 0);
    }
    50% {
        -webkit-transform: rotate(-5deg) translate(5px, 0);
                transform: rotate(-5deg) translate(5px, 0);
    }
    100% {
        -webkit-transform: rotate(5deg) translate(-5px, 0);
                transform: rotate(5deg) translate(-5px, 0);
    }
}

@keyframes style_dancing__3DzW9 {
    0% {
        -webkit-transform: rotate(5deg) translate(-5px, 0);
                transform: rotate(5deg) translate(-5px, 0);
    }
    50% {
        -webkit-transform: rotate(-5deg) translate(5px, 0);
                transform: rotate(-5deg) translate(5px, 0);
    }
    100% {
        -webkit-transform: rotate(5deg) translate(-5px, 0);
                transform: rotate(5deg) translate(-5px, 0);
    }
}

.style_memeTxt__1hWYA {
    display: block;
    line-height: 110px;
}

.style_memeImg__3_974 {
    width: 500px
}

@media only screen and (max-width: 1040px) {
    .style_frame__1F6J0 {
        width: 90vw;
        height: 104vw;
        margin: 3vw auto
    }

    .style_langList__1FCbL {
        width: 20vw;
        height: 100vw;
        padding: 2vw;
    }

    .style_langArena__3mTHk {
        position: relative;
        display: inline-block;
        width: calc(66vw - 1px);
        height: 104vw;
        overflow: hidden;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        text-align: center;
        vertical-align: top;
    }

    .style_langElement__1F0bU {
        font-size: 3vw;
        padding: .5vw;
        -webkit-user-select: none;
           -moz-user-select: none;
            -ms-user-select: none;
                user-select: none;
    }

    .style_langArena__3mTHk .style_langElement__1F0bU {
        position: absolute;
        padding: 0;
        font-size: 4vw;
        width: 33vw;
        height: 10vw;
        line-height: 10.4vw;
    }

    .style_memeTxt__1hWYA {
        display: block;
        line-height: 28vw;
        font-size: 5vw;
    }

    .style_memeImg__3_974 {
        width: 60vw;
    }
}
