.frame {
    width: 1000px;
    height: 600px;
    margin: 20px auto;
    border: 1px solid #999;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
}

.langList {
    display: inline-block;
    width: 150px;
    height: 580px;
    text-align: left;
    padding: 10px;
    background: rgba(255, 255, 255, 0.1);
    border-right: 1px solid #999;
    overflow: hidden;
}

.langArena {
    position: relative;
    display: inline-block;
    width: 829px;
    height: 600px;
    overflow: hidden;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    text-align: center;
}

.langElement {
    font-size: 15pt;
    padding: 2px;
    user-select: none;
}

.langArena .langElement {
    position: absolute;
    width: 200px;
    height: 116px;
    line-height: 116px;
    animation: dancing 1.3s infinite;
    cursor: pointer;
    text-align: center;
}

@keyframes dancing {
    0% {
        transform: rotate(5deg) translate(-5px, 0);
    }
    50% {
        transform: rotate(-5deg) translate(5px, 0);
    }
    100% {
        transform: rotate(5deg) translate(-5px, 0);
    }
}

.memeTxt {
    display: block;
    line-height: 110px;
}

.memeImg {
    width: 500px
}

@media only screen and (max-width: 1040px) {
    .frame {
        width: 90vw;
        height: 104vw;
        margin: 3vw auto
    }

    .langList {
        width: 20vw;
        height: 100vw;
        padding: 2vw;
    }

    .langArena {
        position: relative;
        display: inline-block;
        width: calc(66vw - 1px);
        height: 104vw;
        overflow: hidden;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        text-align: center;
        vertical-align: top;
    }

    .langElement {
        font-size: 3vw;
        padding: .5vw;
        user-select: none;
    }

    .langArena .langElement {
        position: absolute;
        padding: 0;
        font-size: 4vw;
        width: 33vw;
        height: 10vw;
        line-height: 10.4vw;
    }

    .memeTxt {
        display: block;
        line-height: 28vw;
        font-size: 5vw;
    }

    .memeImg {
        width: 60vw;
    }
}