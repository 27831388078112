.player {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 70px;
    background: #282828;
    border-top: 1px solid black;
    color: white;
}

.text {
    width: 100%;
    text-align: center;
    margin: 10px;
    font-size: 20pt;
}

.progress {
    display: block;
    margin: 0 auto;
    width: calc(100% - 40px) !important;
}

.progressSymbol {
    display: none !important;
}

.mute {
    position: absolute;
    top: 10px;
    left: 20px;
    fill: white;
    filter: invert(.8);
    cursor: pointer;
    transition: filter .2s, transform .2s;
}

.mute:hover {
    filter: invert(1);
}

.mute img {
    width: 35px;
    height: 35px;
}

.highlightMute {
    filter: invert(1) !important;
    animation: shake .2s infinite;
}

@keyframes shake {
    0% {
        transform: translateX(-5px);
    }
    50% {
        transform: translateX(5px);
    }
    100% {
        transform: translateX(-5px);
    }
}


@media only screen and (max-width: 1040px) {
    .player {
        height: 20vw;
    }

    .text {
        position: absolute;
        margin: 0;
        font-size: 5vw;
        left: 20.5vw;
        text-align: left;
        width: 77vw;
        display: table;
        height: 100%;
    }

    .text span {
        display: table-cell;
        vertical-align: middle;
    }

    .player svg {
        position: absolute;
        display: block;
        width: unset;
        top: 2vw;
        left: 2vw;
    }

    .mute {
        position: absolute;
        top: 5vw;
        left: 5vw;
        z-index: 1;
    }

    .mute img {
        width: 10vw;
        height: 10vw;
    }
}